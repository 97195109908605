import React from "react"
import Layout from "../components/Layout"
import Form from "../components/Form"

const contact = () => {
  return <Layout>
    <section className="contact-page">
      <article className="contact-form">
        <h3>Contact us</h3>
        <Form/>
        <h4>More ways to reach us</h4>
        <p>Email: hello@techspace.ae</p>
        <p></p>
      </article>
    </section>
  </Layout>
}

export default contact
